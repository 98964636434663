<template>
  <div class="home">
    <my-swiper :bannerList="bannerList"></my-swiper>
    <div class="yxj-container" style="overflow: visible">
      <div class="qr-area page-container swer">
        <div class="qr-center mobile-qr box" :style="!$store.getters.mobile ? 'max-width: 390px' : ''">
          <div class="wx-tips">助力医生临床决策和职业成长</div>
          <div class="wx-label">
            <p>为医生提供优质的医学内容、实用的医学工具、同行交流的平台与职业成长服务。</p>
          </div>
        </div>
        <div class="qr-center mobile-qr box">
          <div class="wx-tips">助力创新药卓越营销</div>
          <div class="wx-label">
            <p>为创新药的循证医学信息传播、创新医生教育和数字化营销，提供一站式解决方案。</p>
          </div>
        </div>
        <div class="qr-center mobile-qr box">
          <div class="wx-tips wx-col">助力基层医院学科建设</div>
          <div class="wx-label">
            <p>为专科建设赋能，帮助基层（地/县级）医疗机构提升综合服务能力。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  created () {
  },
  data () {
    return {
      bannerList: [
        {
          to: '/',
          img: require('@/assets/images/banner18.jpg'),
          mobileImg: require('@/assets/images/banner18m.png'),
          info: '医学界创始于亚洲医疗中心城市 - 上海'
        },
        {
          to: '/',
          img: require('@/assets/images/banner20.jpg'),
          mobileImg: require('@/assets/images/banner20m.png'),
          info: '医学界上海总部 - 五角场万达广场'
          // info: '医学界总部位于上海科创高地 - 五角场'
        },
        {
          to: '/',
          img: require('@/assets/images/banner02.jpg'),
          mobileImg: require('@/assets/images/banner02m.png'),
          info: '2019年11月16-17日，第一届“基医会” 隆重召开'
        },
        {
          to: '/',
          img: require('@/assets/images/banner25.jpg'),
          mobileImg: require('@/assets/images/banner25m.png'),
          info: '2019年首届价值医疗泰山奖：李光伟教授'
        },
        {
          to: '/',
          img: require('@/assets/images/banner12.jpg'),
          mobileImg: require('@/assets/images/banner12m.png'),
          info: '医学界上海办位于五角场万达广场A座19层'
        }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
